




























import { Component, Vue, Watch, Ref, Mixins } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TimelineSearch from '@/components/organisms/TimelineSearch.vue'
import Conditions from '@/components/organisms/Conditions.vue'
import StudentInformationTimeline from '@/components/organisms/v3/StudentInformationTimeline.vue'
import ListStudentNeedSupport from '@/components/organisms/ListStudentNeedSupport.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import TabBase from '@/components/atoms/v3/TabBase.vue'

export type LessonStatus = 'start' | 'participate' | 'restart' | 'complete'

@Component({
  components: {
    TimelineSearch,
    TitleBase,
    TitleTextBase,
    Conditions,
    StudentInformationTimeline,
    ListStudentNeedSupport,
    SelectBase,
    TabBase,
  },
})
export default class Timeline extends Mixins(LocalMoment) {
  private conditionsDatas = []

  private timelineDatas = []

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private lastData: any = {}
  private tab = ''

  private tabBaseDatas: { link: string; name: string }[] = [
    {
      link: '/teacher/v3/timeline',
      name: 'タイムライン',
    },
    {
      link: '/teacher/v3/report',
      name: 'メモ',
    },
  ]

  private async loadDatas() {
    const params = this.conditionsDatas.reduce((accumlator: any, condition: any): any => {
      accumlator[condition.key] = condition.value
      return accumlator
    }, {})

    // 期間開始に最終レコードの学習開始日時を設定
    if (this.lastData.startedAt) params.startedAtLteq = this.lastData.startedAt

    // TODO: 生徒アイコンは未定
    await Vue.prototype.$http.httpWithToken
      .post('/v3/timelines', { branchId: this.branchId, q: params, lastData: this.lastData })
      .then((res: any) => {
        const add_timelines: [] = res.data.timelines.map((timeline: any) => {
          return {
            id: timeline.studentCode,
            name: timeline.nickname,
            icon: '',
            school: timeline.schoolName,
            grade: timeline.gradeName,
            date: timeline.studyDate,
            time: timeline.studyTime,
            type: timeline.studyType,
            item_text:
              timeline.isVictoryHistory && timeline.lessonName ? '【 V 】' + timeline.lessonName : timeline.lessonName,
            material: timeline.lessonType,
            study_time: timeline.duration,
            study_count: timeline.completeCount,
            result: { all: timeline.questionCount, correct: timeline.correctCount },
            learningFlag: timeline.learningFlag,
            resultDrillId: timeline.resultDrillId,
            resultDrillVersion: timeline.resultDrillVersion,
            isModeDefault: timeline.isModeDefault,
            isPause: timeline.isPause,
          }
        })
        this.timelineDatas.push(...add_timelines)
        this.lastData = res.data.meta.lastData
        // 最終行の場合、監視終了
        if (!add_timelines.length) this.observer.disconnect()
      })
  }

  /** 声掛けアラート用のデータ */
  private resultAttentions: {
    id: number
    userId: number
    lessonUserId: number
    point: number
    totalPoint: number
    reason: string
    alertText: string
    timestamp: string
  }[] = []

  private lessonDate = this.today().format('YYYY-MM-DD')
  private lessonOptionDatas: object[] = []
  private selectedLesson: string | null = null
  private lessonId: string | null = null // 授業ID
  private lessonStatus: LessonStatus = 'start' // 授業ステータス

  private async setConditionsDatas(datas: any) {
    this.conditionsDatas = datas
    this.setTimelineCookie()
  }

  @Ref() timelineSearch!: TimelineSearch

  private removeConditionsData(label: string) {
    this.timelineSearch.removeConditionsData(label)
    this.conditionsDatas = this.conditionsDatas.filter((condition: { label: string }) => condition.label !== label)
    this.setTimelineCookie()
  }

  private async setTimelineCookie() {
    // クッキーに検索条件をセット
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['timelineConditions'] = this.conditionsDatas
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  @Watch('conditionsDatas', { deep: true })
  private conditionsDatasChange() {
    // 条件が設定された時の初回取得
    this.timelineDatas = []
    this.lastData = {}
    this.loadDatas()
    this.observer.observe(this.observeElement)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  @Watch('selectedLesson')
  async onSelectedLessonChange(newLesson: string) {
    const lessonInfo = newLesson.split(',')
    this.lessonId = lessonInfo[0]
    this.lessonStatus = lessonInfo[1] as LessonStatus
  }

  /**
   * 授業開始ボタン押下時の処理
   */
  private startLesson() {
    this.getResultAttentions()
  }

  private observer!: IntersectionObserver
  @Ref() observeElement!: HTMLElement

  private async getResultAttentions(): Promise<void> {
    Vue.prototype.$logger.info('-- getResultAttentions')
    try {
      const result = await Vue.prototype.$http.httpWithToken.get(`/history/resultAttention/${this.lessonId}`)
      Vue.prototype.$logger.info(result)
      this.resultAttentions = result.data
    } catch {
      // TODO エラー処理
      alert('エラーが発生しました')
      throw 'Drill Error!'
    }
  }

  private mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        if (entry && entry.isIntersecting) {
          if (Object.keys(this.lastData).length) {
            // 追加取得
            this.loadDatas()
          }
        }
      },
      { rootMargin: '200px 0px' }
    )
    this.observer.observe(this.observeElement)
    // this.loadLessons()
  }
  // ソア トン バオ
  private async toggleSupporterCall(userId: number): Promise<any> {
    Vue.prototype.$logger.info('-- toggleSupporterCall')
    Vue.prototype.$logger.info(userId)
    try {
      await Vue.prototype.$http.httpWithToken.patch(`/history/resultAttention/${this.lessonId}/setCalledAt/${userId}`)
      // TODO 声掛け済みとする場合にデータベースを更新する
      const index = this.resultAttentions.findIndex((studentData: any) => studentData.userId === userId)
      this.resultAttentions.splice(index, 1)
    } catch {
      // TODO エラー処理
      alert('エラーが発生しました')
      throw 'Drill Error!'
    }
  }

  private async loadLessons() {
    await Vue.prototype.$http.httpWithToken
      .get('/lessons', { params: { branchId: this.branchId, date: this.lessonDate } })
      .then((res: any) => {
        this.lessonOptionDatas = res.data.map(
          (lesson: { lessonId: number; className: string; lessonStatus: string }) => {
            return {
              text: lesson.className,
              value: `${lesson.lessonId},${lesson.lessonStatus}`,
            }
          }
        )
        if (this.lessonOptionDatas.length > 0) {
          this.selectedLesson = this.lessonOptionDatas[0]['value']
        }
        const lessonInfo = this.lessonOptionDatas[0]['value'].split(',')
        this.lessonId = lessonInfo[0]
        this.getResultAttentions()
      })
  }
}
